<template>
  <section class="thirdparty-login d-flex justify-center align-center routes">
    <div
      class="card d-flex flex-column align-center"
      :class="token ? 'border-success' : 'border-error'"
    >
    <v-img src="../../assets/images/login-png.png" width="40%" contain v-if="token"></v-img>
    <v-img src="../../assets/images/login-error.png" width="40%" contain v-else></v-img>
    <h2>Thirdparty Login</h2>
      <h4
        class="white--text mb-5"
        :class="token ? 'green--text' : 'error--text'"
      >
        <template v-if="isLoading"> Please wait ... </template>
        <h3 v-else class="text-center">
          <div v-if="token" class="mt-5">
            <span>Login Done Seccessfully</span>
            <v-icon large right color="success">mdi-check-circle</v-icon>
          </div>
          <div v-else class="mt-5">
            <span class="error--text">Login Feild</span>
            <v-icon color="error" right >mdi-close-circle</v-icon>
          <h5 class="mt-2 error--text" >
            We have problem with your token please try again...
          </h5>
          </div>
          
        </h3>
      </h4>
      <v-btn
        @click="$router.replace('/')"
        color="white"
        text
        :loading="isLoading"
        >Back To Page</v-btn
      >
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
export default {
  data() {
    return {
      token: "",
      isLoading: false,
    };
  },
  created() {
    this.getToken();
  },
  methods: {
    ...mapActions([
      "logout",
      "setThirdpartyToken",
      "getProfileInfo",
      "setThirdpartyUser",
      "metaMaskConnect",
      "tronLinkConnect",
      "swithToPolygon",
      "setAlert",
    ]),
    ...mapMutations(["userClear"]),
    getToken() {
      this.token = this.$route.query.token;
      this.setThirdpartyToken(this.token);
      this.isLoading = true;
      this.getProfileInfo()
        .then((res) => {
          let user = res.result;
          this.setThirdpartyUser(user);
          this.connectToWalletAutomaticly();
        })
        .catch(() => {
          this.token = "";
          this.userClear();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    connectToWalletAutomaticly() {
       this.metaMaskConnect().then(() => {
          this.swithToPolygon();
        });
      
      // if (window.tronWeb !== undefined && window.ethereum === undefined) {
      //   //if just tronLink was installed
      //   this.tronLinkConnect();
      //   if (!window.tronWeb.ready) {
      //     this.setAlert({
      //       message:
      //         "Your tronlink wallet is locked,Unlocked it,and try to connect with it",
      //       type: "warning",
      //     });
      //   }
      // } else if (
      //   window.ethereum !== undefined &&
      //   window.tronWeb === undefined
      // ) {
      //   //if just metamask was installed
      //   this.metaMaskConnect().then(() => {
      //     this.swithToPolygon();
      //   });
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.thirdparty-login {
  background: url("https://fulisbutik.com/wp-content/uploads/2019/01/login-background-images-for-website-10-1400x571.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh !important;
  .card {
    width: 95%;
    max-width: 450px;
    border-radius: 15px;
    padding: 40px 20px;
    background: var(--base-color);
    opacity: .8;
    box-shadow: 10px 25px 12px 2px rgba(11, 11, 11, 0.856);
    &.border-success {
      // border: 4px solid green;
    }
    &.border-error {
      // border: 4px solid red;
    }
  }
}
</style>
